import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_BUSINESS_LINE
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import { message } from 'antd';
import { push } from 'react-router-redux';
// import aes from 'crypto-js/aes'
// import encHex from 'crypto-js/enc-hex'
// import padZeroPadding from 'crypto-js/pad-zeropadding'

// var CryptoJS = require("crypto-js");

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/register', {
      email: email,
      password: password,
      name: name
    }
    ).then(({ data }) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        localStorage.setItem("refresh_token", data.token.refresh_token)
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
        dispatch({ type: USER_DATA, payload: data.user });

        let userBusinessLine = {};

        if (data.user.role_id === 1) {
          userBusinessLine.default = 'NBS-VB';
          userBusinessLine.show_dropdown = true
        } else {
          userBusinessLine.default = data.user.default;
          userBusinessLine.show_dropdown = data.user.show_dropdown
        }

        dispatch({ type: USER_BUSINESS_LINE, payload: userBusinessLine }); // User project type action dispatcher added July 27 2020
      } else {
        console.log("payload: data.error", data.error);
        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};

export const swtichBusinessLine = (projectType, role_id) => {
  localStorage.setItem("currentBusinessLine", projectType);
  return (dispatch) => {
    let project_type_data = {
      default: localStorage.getItem("currentBusinessLine") === null ? 'NBS-VB' : localStorage.getItem("currentBusinessLine"),
      show_dropdown: true
    };
    dispatch({ type: USER_BUSINESS_LINE, payload: project_type_data }); // User project type action dispatcher added July 27 2020

    switch (role_id) {
      case 21:
        dispatch({ type: INIT_URL, payload: '/operationsmanagerlanding' });
        dispatch(push('/operationsmanagerlanding'))
        break;
      case 19:
        dispatch({ type: INIT_URL, payload: '/kammanagerlanding' });
        dispatch(push('/kammanagerlanding'))
        break;
      case 15:
        dispatch({ type: INIT_URL, payload: '/hunterlanding' });
        dispatch(push('/hunterlanding'))
        break;
      case 14:
        dispatch({ type: INIT_URL, payload: '/specialistlanding' });
        dispatch(push('/specialistlanding'))
        break;
      case 12:
        dispatch({ type: INIT_URL, payload: '/salesmanlanding' });
        dispatch(push('/salesmanlanding'))
        break;
      case 11:
        dispatch({ type: INIT_URL, payload: '/salesmanagerlanding' });
        dispatch(push('/salesmanagerlanding'))
        break;
      case 10:
        dispatch({ type: INIT_URL, payload: '/regionalmanagerlanding' });
        dispatch(push('/regionalmanagerlanding'))
        break;
      case 9:
        dispatch({ type: INIT_URL, payload: '/hrmanagerlanding' });
        dispatch(push('/hrmanagerlanding'))
        break;
      case 8:
        dispatch({ type: INIT_URL, payload: '/mdlanding' });
        dispatch(push('/mdlanding'))
        break;
      case 7:
        dispatch({ type: INIT_URL, payload: '/sdmlanding' });
        dispatch(push('/sdmlanding'))
        break;
      case 6:
        dispatch({ type: INIT_URL, payload: '/areabusinesslinemanagerlanding' });
        dispatch(push('/areabusinesslinemanagerlanding'))
        break;
      case 5:
        dispatch({ type: INIT_URL, payload: '/areapayrollmanagerlanding' });
        dispatch(push('/areapayrollmanagerlanding'))
        break;
      case 4:
        dispatch({ type: INIT_URL, payload: '/areacbmlanding' });
        dispatch(push('/areacbmlanding'))
        break;
      case 3:
        dispatch({ type: INIT_URL, payload: '/areahrdirectorlanding' });
        dispatch(push('/areahrdirectorlanding'))
        break;
      case 2:
        dispatch({ type: INIT_URL, payload: '/areamdlanding' });
        dispatch(push('/areamdlanding'))
        break;
      default:

        dispatch({ type: INIT_URL, payload: '/dashboard' });
        dispatch(push('/dashboard'))

    }

  }
} // Custom action for switching project type - Added on July 27 2020

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    // let key = encHex.parse("0123456789abcdef0123456789abcdef");
    // let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
    // var encrypted = CryptoJS.AES.encrypt(password, email).toString();
    // let encrypted = aes.encrypt(password, key, {iv:iv, padding:padZeroPadding}).toString();
    // // for decrpt
    // var bytes = CryptoJS.AES.decrypt(encrypted, email);
    // var originalText = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(encrypted, password,email)
    axios.post('api/getauth/token', {
      username: email,
      password: password,
      iam: "web",
      // grant_type: 'password',
      // client_id: '2',
      // client_secret: 'wV19xMZIQsLrENWVcQ2mCUzjhJxj1eLd7Hfgpmj8',
    }
    ).then(({ data }) => {
      if (data.access_token) {

        localStorage.setItem("token", JSON.stringify(data.access_token));
        localStorage.setItem("refresh_token", data.refresh_token)
        axios.defaults.headers.common['access-token'] = "Bearer " + data.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data.user });

        let userBusinessLine = {};

        if (data.user.role_id === 1) {
          userBusinessLine.default = 'NBS-VB';
          userBusinessLine.show_dropdown = true

        } else {
          userBusinessLine.default = data.user.default;
          userBusinessLine.show_dropdown = data.user.show_dropdown
        }

        localStorage.setItem("currentBusinessLine", userBusinessLine.default);
        localStorage.setItem("currentRole", data.user.role_id);
        localStorage.setItem("currentPath", "dashboard")
        dispatch({ type: USER_BUSINESS_LINE, payload: userBusinessLine }); // User project type action dispatcher added July 27 2020
        

        console.log('data.user.role_id', data.user.role_id);

        /* if user is Operations manager: */
        if (data.user.role_id === 21) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/operationsmanagerlanding' });
        }

         /* if user is Farmer: */
        if (data.user.role_id === 20) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/farmerlanding' });
        }
        
        /* if user is KAM: */
        if (data.user.role_id === 19) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/kammanagerlanding' });
        }
        /* if user is Regional Business controller: */
        if (data.user.role_id === 18) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/regionalbusinesscontrollerlanding' });
        }
        /* if user is Business controllers */
        if (data.user.role_id === 17) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/businesscontrollerlanding' });
        }
        /* if user is KBS User */
         if (data.user.role_id === 16) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/kbsuserlanding' });
        }
       /* if user is Hunter */
       if (data.user.role_id === 15) {
        localStorage.setItem("currentRole", data.user.role_id);
        dispatch({ type: INIT_URL, payload: '/hunterlanding' });
      }
        /* if user is Specialist */
        if (data.user.role_id === 14) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/specialistlanding' });
        }
        /* If user is Salesman */
        if (data.user.role_id === 12) {
          localStorage.setItem("currentRole", data.user.role_id);
          dispatch({ type: INIT_URL, payload: '/salesmanlanding' });
        }
        /* If user is Sales Manager */
        if (data.user.role_id === 11) {
          dispatch({ type: INIT_URL, payload: '/salesmanagerlanding' });
        }
        /* If user is Regional Manager */
        if (data.user.role_id === 10) {
          dispatch({ type: INIT_URL, payload: '/regionalmanagerlanding' });
        }
        /* If user is HR Manager */
        if (data.user.role_id === 9) {
          dispatch({ type: INIT_URL, payload: '/hrmanagerlanding' });
        }
        /* If user is MD */
        if (data.user.role_id === 8) {
          dispatch({ type: INIT_URL, payload: '/mdlanding' });
        }
        /* If user is SDM */
        if (data.user.role_id === 7) {
          dispatch({ type: INIT_URL, payload: '/sdmlanding' });
        }
        /* If user is Area Businessline Manager */
        if (data.user.role_id === 6) {
          dispatch({ type: INIT_URL, payload: '/areabusinesslinemanagerlanding' });
        }
        /* If user is Area Payroll Manager */
        if (data.user.role_id === 5) {
          dispatch({ type: INIT_URL, payload: '/areapayrollmanagerlanding' });
        }
        /* If user is Area CBM */
        if (data.user.role_id === 4) {
          dispatch({ type: INIT_URL, payload: '/areacbmlanding' });
        }
        /* If user is Area HR Director */
        if (data.user.role_id === 3) {
          dispatch({ type: INIT_URL, payload: '/areahrdirectorlanding' });
        }
        /* If user is Area MD */
        if (data.user.role_id === 2) {
          dispatch({ type: INIT_URL, payload: '/areamdlanding' });
        }

        dispatch({ type: USER_TOKEN_SET, payload: data.access_token });
      } else {
        console.log(data, "messg")
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    }).catch(function (error) {
      console.log(error, "messg")
      // var message='';
      // if(/401/i.test(error.message)){
      //   message = error.message.replace("Request failed with status code 401","Invalid Credentials")
      // }
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message, message);
    });
  }
};

export const userSwitch = (userId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('api/switchuser', {
      new_user_id: userId
    }
    ).then(({ data }) => {
      console.log(data, "data")
      let responseData = data.data;
      if (responseData.access_token) {
        localStorage.setItem("token", JSON.stringify(responseData.access_token));
        localStorage.setItem("refresh_token", responseData.refresh_token)
        axios.defaults.headers.common['access-token'] = "Bearer " + responseData.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: responseData.user });

        let userBusinessLine = {};

        if (responseData.user.role_id === 1) {
          userBusinessLine.default = 'NBS-VB';
          userBusinessLine.show_dropdown = true
          localStorage.setItem("currentRole", responseData.user.role_id);


        } else {
          userBusinessLine.default = responseData.user.default;
          userBusinessLine.show_dropdown = responseData.user.show_dropdown

        }
        console.log(userBusinessLine.default, "busline")
        localStorage.setItem("currentBusinessLine", userBusinessLine.default);
        // localStorage.setItem("currentBusinessLine",localStorage.getItem("currentBusinessLine"))

        dispatch({ type: USER_BUSINESS_LINE, payload: userBusinessLine }); // User project type action dispatcher added July 27 2020
        

        // if user is Operations manager //
        if (responseData.user.role_id === 21) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "operationsmanagerlanding");
          dispatch({ type: INIT_URL, payload: '/operationsmanagerlanding' });
          dispatch(push('/operationsmanagerlanding'))
        }

        // if user is Farmer //
        if (responseData.user.role_id === 20) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "farmerlanding");
          dispatch({ type: INIT_URL, payload: '/farmerlanding' });
          dispatch(push('/farmerlanding'))
        }
        
        // if user is KAM //
        if (responseData.user.role_id === 19) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "kammanagerlanding");
          dispatch({ type: INIT_URL, payload: '/kammanagerlanding' });
          dispatch(push('/kammanagerlanding'))
        }
         // if user is Regional Business controller //
         if (responseData.user.role_id === 18) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "regionalbusinesscontrollerlanding");
          dispatch({ type: INIT_URL, payload: '/regionalbusinesscontrollerlanding' });
          dispatch(push('/regionalbusinesscontrollerlanding'))
        }
        // if user is Business controller //
         if (responseData.user.role_id === 17) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "businesscontrollerlanding");
          dispatch({ type: INIT_URL, payload: '/businesscontrollerlanding' });
          dispatch(push('/businesscontrollerlanding'))
        }
         // if user is KBS User //
         if (responseData.user.role_id === 16) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "kbsuserlanding");
          dispatch({ type: INIT_URL, payload: '/kbsuserlanding' });
          dispatch(push('/kbsuserlanding'))
        }
        // if user is Hunter //
        if (responseData.user.role_id === 15) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "hunterlanding");
          dispatch({ type: INIT_URL, payload: '/hunterlanding' });
          dispatch(push('/hunterlanding'))
        }
        // if user is Specialist //
        if (responseData.user.role_id === 14) {
              localStorage.setItem("currentRole", responseData.user.role_id);
              localStorage.setItem("currentPath", "specialistlanding");
              dispatch({ type: INIT_URL, payload: '/specialistlanding' });
              dispatch(push('/specialistlanding'))
            }
        /* If user is Salesman */
        if (responseData.user.role_id === 12) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "salesmanlanding");
          dispatch({ type: INIT_URL, payload: '/salesmanlanding' });
          dispatch(push('/salesmanlanding'))
        }
     
        /* If user is Sales Manager */
        if (responseData.user.role_id === 11) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "salesmanagerlanding");
          dispatch({ type: INIT_URL, payload: '/salesmanagerlanding' });
          dispatch(push('/salesmanagerlanding'))

        }
        /* If user is Regional Manager */
        if (responseData.user.role_id === 10) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "regionalmanagerlanding");
          dispatch({ type: INIT_URL, payload: '/regionalmanagerlanding' });
          dispatch(push('/regionalmanagerlanding'))

        }
        /* If user is HR Manager */
        if (responseData.user.role_id === 9) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "hrmanagerlanding");
          dispatch({ type: INIT_URL, payload: '/hrmanagerlanding' });
          dispatch(push('/hrmanagerlanding'))

        }
        /* If user is MD */
        if (responseData.user.role_id === 8) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "mdlanding");
          dispatch({ type: INIT_URL, payload: '/mdlanding' });
          dispatch(push('/mdlanding'))
        }
        /* If user is SDM */
        if (responseData.user.role_id === 7) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "sdmlanding");
          dispatch({ type: INIT_URL, payload: '/sdmlanding' });
          dispatch(push('/sdmlanding'))
        }
        /* If user is Area Businessline Manager */
        if (responseData.user.role_id === 6) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "areabusinesslinemanagerlanding");
          dispatch({ type: INIT_URL, payload: '/areabusinesslinemanagerlanding' });
          dispatch(push('/areabusinesslinemanagerlanding'))
        }
        /* If user is Area Payroll Manager */
        if (responseData.user.role_id === 5) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "areapayrollmanagerlanding");
          dispatch({ type: INIT_URL, payload: '/areapayrollmanagerlanding' });
          dispatch(push('/areapayrollmanagerlanding'))
        }
        /* If user is Area CBM */
        if (responseData.user.role_id === 4) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "areacbmlanding");
          dispatch({ type: INIT_URL, payload: '/areacbmlanding' });
          dispatch(push('/areacbmlanding'))
        }
        /* If user is Area HR Director */
        if (responseData.user.role_id === 3) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "areahrdirectorlanding");
          dispatch({ type: INIT_URL, payload: '/areahrdirectorlanding' });
          dispatch(push('/areahrdirectorlanding'))
        }
        /* If user is Area MD */
        if (responseData.user.role_id === 2) {
          localStorage.setItem("currentRole", responseData.user.role_id);
          localStorage.setItem("currentPath", "areamdlanding");
          // / localStorage.setItem("currentBusinessLine",localStorage.getItem("currentBusinessLine"))

          dispatch({ type: INIT_URL, payload: '/areamdlanding' });
          dispatch(push('/areamdlanding'))
        }
        dispatch({ type: USER_TOKEN_SET, payload: responseData.access_token });
      } else {
        message.error(responseData.error_message + "!! Contact Administrator", 10)
        dispatch({ type: FETCH_ERROR, payload: responseData.error_message });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: 'Session expired / Network error! Please Sign In again.' });
      console.log("Error****:", error.message);
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      //this.userSignOut();
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.get('api/getuser',
    ).then(({ data }) => {
      if (data.id) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data });

        let userBusinessLine = {};

        if (data.role_id === 1) {
          userBusinessLine.default = localStorage.getItem("currentBusinessLine") === null ? 'NBS-VB' : localStorage.getItem("currentBusinessLine");
          userBusinessLine.show_dropdown = true
        } else {
          userBusinessLine.default = localStorage.getItem("currentBusinessLine") === null ? 'NBS-VB' : localStorage.getItem("currentBusinessLine");
          userBusinessLine.show_dropdown = data.show_dropdown
        }

        dispatch({ type: USER_BUSINESS_LINE, payload: userBusinessLine }); // User project type action dispatcher added July 27 2020
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: 'Session expired / Network error! Please Sign In again.' });
      console.log("Error****:", error.message);
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      //this.userSignOut();
    });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.get('api/logout',
    ).then(({ data }) => {
      if (data.data.id) {
        setTimeout(() => {
          localStorage.removeItem("sdmTickets")
          localStorage.removeItem("selectedOption")
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("selectedYear");
          localStorage.removeItem("selectedQuarter");
          localStorage.removeItem("selectedPayoutPeriod");
          localStorage.removeItem("selectedBusinessLine");
          localStorage.removeItem("selectedRole");
          localStorage.removeItem("selectedYearApproved");
          localStorage.removeItem("selectedQuarterApproved");
          localStorage.removeItem("selectedPayoutPeriodApproved");
          localStorage.removeItem("selectedBusinessLineApproved");
          localStorage.removeItem("selectedRoleApproved");
          localStorage.removeItem("currentBusinessLine")
          localStorage.removeItem("currentRole");
          localStorage.removeItem("currentPath");
		  localStorage.removeItem("showBulkDeleteMessage");
		  localStorage.removeItem("bulkDeleteMessages"); 
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
        }, 750);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: 'Session expired / Network error! Please Sign In again.' });
      console.log("Error****:", error.message);
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      //this.userSignOut();
    });
  }
};
