import axios from 'axios';

export default axios.create({
  //baseURL: "https://staging.kone.rtcuae.com/api/",
  //baseURL: `http://kone-neb-vb-va-combined-api-local.com/`, // Local
  baseURL: `https://staging.kone.altd.in/api/`, // Staging
  //baseURL: `https://icm.kone.com/api/`, // Live

  //baseURL: `http://13.235.109.138/api/public/`, // new
  //baseURL: `http://13.233.68.76/api/public/`,
  icmURL: `https://icm.kone.com/`,
  headers: {
    'Content-Type': 'application/json',
  }
});